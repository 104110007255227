import { cx } from "@@panda/css";
import { HTMLStyledProps, styled } from "@@panda/jsx";
import * as RadixTabs from "@radix-ui/react-tabs";
import { useState } from "react";
import { useQueryState } from "utility/hooks/useQueryState";

import { TabsContext } from "./context";
import { TabsInternalContext, useTabsInternalContext } from "./internalContext";
import { Variants, tabsRecipe } from "./styles";

Tabs.TabList = function TabList({ className, ...p }: RadixTabs.TabsListProps) {
  const { classes } = useTabsInternalContext();
  return <RadixTabs.List {...p} className={cx(classes.tablist, className)} />;
};

Tabs.Tab = function Tab<TValue extends string>({
  className,
  children,
  value,
  ...p
}: Omit<RadixTabs.TabsTriggerProps, "value"> & {
  value: TValue;
}) {
  const { classes } = useTabsInternalContext();
  return (
    <RadixTabs.Trigger
      className={cx(classes.tab, className)}
      value={value}
      {...p}
    >
      {children ?? value}
    </RadixTabs.Trigger>
  );
};

Tabs.Panels = function Panels({ className, ...p }: HTMLStyledProps<"div">) {
  const { classes } = useTabsInternalContext();
  return <styled.div {...p} className={cx(classes.panels, className)} />;
};

Tabs.Panel = function Panel<TValue extends string>({
  className,
  ...p
}: Omit<RadixTabs.TabsContentProps, "value"> & {
  value: TValue;
}) {
  const { classes } = useTabsInternalContext();
  return <RadixTabs.Content {...p} className={cx(classes.panel, className)} />;
};

// ---

type Props<TDefaultValue extends string> = Omit<
  RadixTabs.TabsProps,
  "defaultValue" | "variant"
> & { defaultValue: TDefaultValue; variant?: Variants; useUrlState?: boolean };

export function Tabs<TDefaultValue extends string>({
  children,
  variant,
  className,
  useUrlState = true,
  ...rest
}: Props<TDefaultValue>) {
  const classes = tabsRecipe({ visual: variant });
  const [internalState, setInternalState] = useState<string>(rest.defaultValue);
  const [urlState, setUrlState] = useQueryState("tab", {
    fallback: rest.defaultValue,
  });

  const value = useUrlState ? urlState : internalState;
  const onValueChange = useUrlState ? setUrlState : setInternalState;

  return (
    <RadixTabs.Root
      className={cx(classes.root, className)}
      value={value}
      onValueChange={onValueChange}
      {...rest}
    >
      <TabsContext.Provider value={{ value }}>
        <TabsInternalContext.Provider value={{ classes }}>
          {children}
        </TabsInternalContext.Provider>
      </TabsContext.Provider>
    </RadixTabs.Root>
  );
}

/**
 * Creates typed tabs based on the allowed values.
 * @param -  Array of allowed values (strings) OR a string union type argument
 */
export function createTypedTabs<const TAllowedValues extends string>(
  _values?: readonly TAllowedValues[]
) {
  type Value = TAllowedValues extends []
    ? TAllowedValues[number]
    : TAllowedValues;

  return {
    Tabs: Tabs<Value>,
    TabList: Tabs.TabList,
    Tab: Tabs.Tab<Value>,
    Panels: Tabs.Panels,
    Panel: Tabs.Panel<Value>,
  };
}
