import { DealStatus, Primary, Secondary } from "@internal/gql/generated/types";
import { StepUrls } from "screens/create-deal/steps";

export const tabs = ["Created", "Invited", "Portfolio", "Passed"] as const;
export type TabType = (typeof tabs)[number];

interface GetDealUrlArgs {
  id: string;
  type: "admin" | "invest" | "review" | "draft" | "closing";
  syndSlug: string;
  dealSlug: string;
}

export function getDealUrl({ id, dealSlug, syndSlug, type }: GetDealUrlArgs) {
  if (type === "admin") {
    return `/${syndSlug}/${dealSlug}/admin`;
  }

  if (type === "closing") {
    return `/${syndSlug}/${dealSlug}/admin`;
  }

  if (type === "invest") {
    return `/${syndSlug}/${dealSlug}/invest`;
  }

  if (type === "review") {
    return `/create-deal/${id}/${StepUrls.ReviewSubmit}`;
  }

  return `/create-deal/${id}/${StepUrls.CompanyInfo}`;
}

export function isDraft(status: DealStatus) {
  return status === "DRAFT" || status === "REVIEW";
}

export const rowZIndex = 10;

type InstrumentTypename =
  | Primary["instrument"]["__typename"]
  | Secondary["instrument"]["__typename"];

export const formatInstrumentTypename = (
  instrument: InstrumentTypename | null | undefined
) => {
  const fallback = "-";
  if (!instrument) return fallback;

  const map = {
    InvestmentInstrumentConvertible: "Convertible note",
    InvestmentInstrumentEquity: "Equity",
    InvestmentInstrumentSafe: "SAFE",
    InvestmentInstrumentSaft: "SAFT",
    InvestmentInstrumentSeedfast: "ASA",
    InvestmentInstrumentSecondary: "Equity",
  } as const satisfies Record<InstrumentTypename, string>;

  return map[instrument] ?? fallback;
};
