import { createContext, useContext } from "react";

type TabsContextShape = {
  value: string;
};

export const TabsContext = createContext<TabsContextShape>({
  value: "",
});

export function useTabsContext() {
  const result = useContext(TabsContext);
  return result;
}
