import { createContext, useContext } from "react";

import { tabsRecipe } from "./styles";

interface TabsContextShape {
  classes: ReturnType<typeof tabsRecipe>;
}

export const TabsInternalContext = createContext<TabsContextShape>({
  classes: {},
});

export function useTabsInternalContext() {
  const result = useContext(TabsInternalContext);
  return result;
}
